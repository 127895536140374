<template>
  <b-navbar
    type="light"
    class="d-print-none d-flex flex-row justify-content-start align-items-center px-2 px-sm-3 px-md-6 top-navbar"
    :class="isShowSideMenu ? 'top-navbar collapsed' : 'top-navbar'"
  >
 
  <b-navbar-brand
      class="d-flex align-self-start flex-row align-items-start justify-content-start p-0 pl-4 mb-4 py-2"
      v-if="!isShowSideMenu"
    >
      <div class="logo-box">
      
        <b-img
          src="@/assets/TopbarLogo.png"
          class="img-logo px-0"
          height="40px"
          alt="Figa"
        ></b-img>
      </div>
    </b-navbar-brand>
    <b-button
          class="d-block d-md-none border border-0 px-4 py-1 mr-2"
          variant="transparent"
          @click="showSideMenu"
      >
        <b-icon icon="menu-button" size="lg" aria-label="Menu">Hello</b-icon>
      </b-button>
    <b-row
      class="d-flex flex-row align-items-center justify-content-start mx-0 mr-auto"
    >

 

   
      <!-- title -->
      <template v-if="subPage">
        <h5
          class="font-primary display-text text-main-green text text-left mb-0"
        >
          {{ parentPage }}
        </h5>
        <b-icon
          icon="chevron-right"
          aria-label="SubMenu"
          class="mx-2 mt-1"
        ></b-icon>
        <h5 class="font-primary display-text text-main-green text-left mb-0">
          {{ subPage }}
        </h5>
      </template>
      <template v-else>
        <h5 class="font-primary display-text text-main-green text-left mb-0">
          {{ title }}
        </h5>
      </template>
   
    </b-row>
    <!-- user account icon -->
    <b-dropdown
      variant="white"
      class="user-icon-button ml-0 ml-sm-2"
      toggle-class="d-flex flex-row align-items-center justify-content-between border-0 px-2 py-2 p-0 text-decoration-none user-icon-pill"
      menu-class="w-100 bg-white rounded-lg user-icon-menu"
      right
      no-caret
    >
      <template #button-content>
        <b-avatar
          class="mr-2 bg-white border border-dark avatar-style"
          size="34px"
        >{{AdminName}} </b-avatar>
        
        <template v-if="isBusinessLoading">
          <b-overlay
            :show="isBusinessLoading"
            no-wrap
            variant="white"
            spinner-small
          ></b-overlay>
        </template>
        <template v-else>
          <span
            class="mr-3 d-block text-left text-medium text-dark font-secondary font-weight-bold text-truncate mb-1"
          >
            <!-- Finance -->
            {{ currentBusiness.name ? currentBusiness.name : "" }}
          </span>
        </template>
        <b-icon
          icon="chevron-right"
          class="mb-1"
          aria-label="View Menu"
          size="sm"
          font-scale="0.9"
        ></b-icon>
      </template>
      <b-dropdown-group>
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-4"
          no-gutters
        >
          <span
            class="d-block text-left text-medium text-main-green font-weight-bold text-truncate my-2"
          >
         Admin
          </span>
          
          <!-- </b-col> -->
        </b-row>
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-4"
          no-gutters
        >
          <p
            class="d-block text-left text-medium ash--text font-weight-normal text-truncate"
          >
            Master
      </p>
          
          <!-- </b-col> -->
        </b-row>
        <hr style="width: 92%; text-align: start; margin-top: 1em" />
        <!-- current user details -->
  
     
        <!-- full row Statement date -->
        <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-4 pb-3"
          no-gutters
        > -->
    
        <!-- </b-row> -->
        <b-dropdown-item-button
          variant="main-green"
          button-class="px-4 pt-1"
          @click="settingsFn"
        >
          <b-icon
            icon="person-circle"
            class="pt-1 text-main-green"
            scale="1.1"
            aria-hidden="true"
          ></b-icon>
          <span class="text-left text-small text-main-green pl-2"
            >Account Settings</span
          >
        </b-dropdown-item-button>
        <b-dropdown-item-button button-class="px-4 pb-1" @click="logoutFn">
          <b-icon
            icon="box-arrow-in-right"
            class="pt-1 text-main-green"
            scale="1.1"
            aria-hidden="true"
          ></b-icon>
          <span class="text-left text-small text-main-green pl-2">Sign Out</span>
        </b-dropdown-item-button>
        <!-- <hr style="width: 92%; text-align: start; margin-top: 1em" /> -->
        <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 px-4 pb-2"
          no-gutters
        >
          <b-link
            class="text-left font-primary text-gray-750 font-weight-normal mr-3 terms-text"
            >Terms</b-link
          >
          <b-link
            class="text-left font-primary text-gray-750 font-weight-normal ml-2 terms-text"
            >Privacy</b-link
          >
        </b-row> -->
      </b-dropdown-group>
    </b-dropdown>
  </b-navbar>
</template>

<script>

import { mapActions} from "vuex";
import { GetAccountDetails } from "@/services/account.service";

export default {
  name: "TopBar",
  data() {
    return {
      userProfile: null,
      currentBusiness: {},
      isBusinessLoading: false,
      accountData:{
        userName: "",
       
      }
    };
  },
  props: {
    isShowSideMenu: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      return this.$route.name;
    },
    subPage() {
      return this.$route.meta.subPage;
    },
    parentPage() {
      return this.$route.meta.parentPage;
    },
    AdminName(){
      var str = this.accountData.userName;
      let acronym = str.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
      
      return acronym;
    }

  },

  async mounted() {
    await this.initFn();
  },
  methods: {


    ...mapActions({ logOut: "auth/logOut" }),
    async initFn() {
      try {
        let { data } = await GetAccountDetails();
       
        this.accountData = {
          userName: data.name,
          
        };
        console.log( this.accountData.userName)
        this.Admin_name =   Array.from(data.name)[0] ;
       
       
      } catch (error) {
        console.log(error);
      }
    },
    logoutFn() {
      localStorage.clear();
      this.logOut();
      location.replace("/");
    },
    showSideMenu() {
      console.log("clciked")
      this.$store.dispatch("general/setIsSideMenuExpanded", true);
    },
    settingsFn() {
      this.$router.push("/Account");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.top-navbar {
  position: fixed;
  width: 100%;
 
  height: 60px;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    background-color 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.top-navbar .display-text {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #0B9B7D;
  margin-left: 40px;
}
.top-navbar .state-text {
  font-size: 0.9rem;
}
.top-navbar .terms-text {
  font-size: 0.7rem;
}
.img-logo{
  position: absolute;
  width: 50px;
  height: 26px;
  left: 14px; 
  top: 16px;
}
.avatar-style{
  position: absolute;
  width: 25px;
  height: 25px;
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: #333333;
}
.filter-dropdown {
  height: 40px;
  min-width: 180px;
}
.filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.user-icon-button >>> .user-icon-menu {
  min-width: 420px;
}
.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item.active,
.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item:active,
.user-icon-button >>> .user-icon-menu button.dropdown-item.active,
.user-icon-button >>> .user-icon-menu button.dropdown-item:active {
  background-color: var(--white);
}
.workspace-btn {
  color: var(--dark);
}
.workspace-btn:hover {
  color: var(--main-green);
  text-decoration: none;
}
.user-icon-button >>> .user-icon-pill {
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 150ms ease-in, shadow 150ms ease-in;
}
.user-icon-button:hover >>> .user-icon-pill {
  /* border: 1px solid var(--main-green); */
  /* box-shadow: 0 0 7px rgba(0, 0, 0, 0.1); */
}
.user-icon-button >>> .user-icon-pill span.text-small {
  font-size: 0.85rem;
  max-width: 120px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-dropdown {
    min-width: auto;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .top-navbar,
  .top-navbar.collapsed {
    left: 0;
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
    color: white;
  }
  .user-icon-button >>> .user-icon-menu {
    min-width: 240px;
  }
  .top-navbar .display-text {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #0B9B7D;
  margin-left: 40px;
}
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .top-navbar .display-text {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #0B9B7D;
  margin-left: 40px;
}
  .user-icon-button >>> .user-icon-pill .user-avatar {
    font-size: 0.7rem;
  }
  .filter-dropdown {
    height: 35px;
    min-width: auto;
  }
  .user-icon-button >>> .user-icon-menu {
    min-width: 240px;
  }
}
</style>
