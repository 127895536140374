import axios from "axios";

export const GetAccountDetails = async (params) => {
    try {
      return await axios.get("/admin/account",{ params });
    } catch (error) {
      return error;
    }
};

export const ResetPassword = (payload) => {
  return axios.put(`/admin/reset_password`, payload);
};
  